@import '../variables/app_layout_vars.scss';

#newFunction {
  cursor: pointer;
}

.functions-container {
	width: 100%;
  height: 100%;  
  display: flex;
  position: static;
}

.functions-container-left {
	width: 30%;
  height: 100vh;
  border-right: 1px solid #e9ecef;
  overflow-y: scroll;
}

.functions-container-left::-webkit-scrollbar{
  display: none;
}

.functions-container-right {
	background-color: $gray-100;
	width: 70%;
  height: 100vh;
  overflow-y: scroll;

}

.functions-container-right-code {
    background-color: #011627;
    padding: 0.5em;
    width: 70%;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: scroll;
    display: flex;
}

.content-sync {
	  visibility: hidden;
    position: fixed;
    bottom: 3.4%;
    width: 65%;
    background-color: transparent;

    .sync-wrapper {
      align-content: center;
      align-items: center;
      background-color: $white;
      border: 1px solid $gray-300;
      border-radius: 0.25em;
      box-shadow: 0 12px 12px -10px rgba(13, 25, 63, 0.12);
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      height: 2.6875em;
      margin: auto;
      width: 34.875em;


      span {
        background-color: $primary-blue;
        border-radius: 0.25em;
        color: $white;
        cursor: pointer;
        font-size: 0.80em;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        height: 1.85em;
        letter-spacing: normal;
        line-height: 2;
        margin-right: 1em;
        padding: 0.135em 1.0725em;
        width: 5.5625em;
      }
    }
}

.button-sync-git {
  margin-top: 2em;
  text-align: right;
}

.button-update-name-function {
  text-align: right;
}

.functions-titles {
	font-family: BasisGrotesquePro, Arial, sans-serif;
	font-size: 18px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: $black;
	margin-left: 1.5rem;
	margin-top: 2rem;
}

.add-new-repository{
  margin-right: 1em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1em;

  .functions-add{
    font-weight: bold;
    font-size: 1em;
    cursor: pointer;
    font-size: 0.9em;
    width: 30%;
  }
}

.functions-container{
  .error-display{
    font-size: 0.9em;
    font-weight: bold;
    width: 95%;
    margin: 1em 1em 1.5em 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    p{
      padding: 0;
      margin: 0;
      height: 100%;
    }
  }
}


.pop-up-tree-file{
  color: #212529;
  margin: 0 0 0 25%;
  position: absolute;
  background-color: white;
  display: flex;
  align-items: center;

  .gg-shape-triangle {
    position: relative;
    transform: scale(var(--ggs,1));
    width: 22px;
    height: 17px;
    border-left: 3px solid transparent;
    border-bottom: 3px solid white;
    transform: rotate(270deg);
    margin: 0 -5px 0 0;
}
.gg-shape-triangle,
.gg-shape-triangle::before {
    display: block;
    box-sizing: border-box;
    border-right: 3px solid transparent
}
.gg-shape-triangle::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 13px;
    border-left: 1px solid rgba(0,0,0,.15);
    border-top: 1px solid rgba(0,0,0,.15);
    border-bottom: 3px solid transparent;
    transform: rotate(45deg) skew(10deg,10deg);
    left: 2px;
    bottom: -8px
}

  
  .options-pop-up{
    display: flex;
    align-items: center;
    padding: .5rem .75rem;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 3px;
    font-size:  0.9em;
    p{
      margin: 0;
    }
    
    svg{
      color: $alert-red;
      margin: 0 0.5em 0 0;
    }
  }
}

.option-1-pop-up:hover{
  background-color: #e4ecfa;
}

.option-1-pop-up{
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
  border-radius: 2px;
}

.pop-up-display-none{
  display: none;
}

.pop-up-display{
  display: flex;
}

.menu-repository{
  color: #a2a1a1;
}

.new-collection {
  cursor: pointer;
  padding: 0.5em;
  padding-left: 0.8em;
  padding-right: 0.8em;
  &:hover {
    background-color: #e1edff;
    border-radius: 4px;
  }
}

.new-function {
  cursor: pointer;
  padding: 0.5em;
  padding-left: 0.8em;
  padding-right: 0.8em;
  &:hover {
    background-color: #e1edff;
    border-radius: 4px;
  }
}

.new-icon-function {
  color: $primary-blue;
  margin-right: 1em;
}

.new-icon-collection {
  color: $primary-blue;
  margin-right: 1em;
}

.functions-list-container {
  height: 90%;
  margin-top: 1em;
  background-color: #fff;
}


.functions-list-title-container {
  min-height: 10%;


  .flex-funcs-title{
    font-size: 1em;
    margin: 2em 0 2em 2.5em;
    justify-content: space-between;
  }
}

.styledFile{
  padding: 10px 0 10px 20px;
  display: flex;
  align-items: center;

  .file--label{
      border-bottom: 1px solid #f5f6fa;
      width: 100%;
      padding: 0 0 0.5em 0;
  }

  span {
      margin-left: 5px;
  }
}

.styledFolder{
  padding: 10px 0 5px 20px;
  cursor: pointer;

  .folder--label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f5f6fa;
    padding: 0 0 0.5em 0;

    p{
        margin: 0;
    }

    span {
        margin-left: 5px;
    }
  }
}

.styledTree{
  line-height: 1.5;
}



.function-item {
  cursor: pointer;
  padding-top: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
  padding-left: 0.5em;
  border-bottom: 1px solid $gray-200;
  &:hover {
    border-left: 1px solid $navy-blue;
  }

  .function-title-and-time,
  .function-title-and-time-habitat,
  .function-title-and-time-collection {
    align-content: center;
    align-items: center;
    display: flex;
    flex-flow: row nowrap;

    .collection-icon-box {
      background-color: $navy-blue;
      border-radius: 5px;
      height: 2.25em;
      width: 2.25em;
    }

    .collection-title,
    .collection-timestamp {
      color: $black;
      display: block;
      font-family: BasisGrotesquePro, Arial, sans-serif;
    }

    .collection-title {
      font-size: 0.875em;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
    }

    .collection-timestamp {
      opacity: 0.8;
      font-size: 0.75em;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }

  .function-title-and-time {
    justify-content: flex-start;

    .function-title-and-time-image {
      margin-right: 1em;
    }

    .function-title-and-time-text {
      flex-grow: 1;
    }
  }

  .function-title-and-time-habitat,
  .function-title-and-time-collection {
    justify-content: start;

    .collection-icon-box {
      margin-right: 1em;
    }
  }
}

.rename-icon-collection {
  color: $primary-purple;
  margin-right: 1em;
}

.delete-icon-function {
  color: $alert-red;
  margin-right: 1.1em;
}

.button-new-func {
  margin-top: 2em;
  float: right;
}

.cancel-btn {
  margin-right: 1em;
}

.upload-btn {
  background-color: $light-blue;
  text-align: center;
  padding: 1.2em;
  border-radius: 5px;
  color: $primary-blue;
  font-weight: bold;
  cursor: pointer;
}

.upload-success {
  background-color: $success-green;
  text-align: center;
  padding: 1.2em;
  border-radius: 5px;
  color: #4a4a4a;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.upload-icon {
  margin-left: 1em;
}

.upload-input {
  display: none;
}

.func-icon {
  font-size: 1.5em;
  color: $navy-blue;
  margin-right: 1em;
  margin-left: 1em;
  margin-top: 0.25em;
}



.func-item {
  position: relative;
  cursor: pointer;
  margin-top: 1em;
  border-bottom: 1px solid $gray-200;
  &:hover {
    border-left: 2px solid $primary-blue;
  }
  &:last-child {
    border-bottom: 0;
  }

  .func-title-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;

    .func-title {
      flex-grow: 1;
      font-size: 1em;
    }
  }

  .file-tree-cont-wrapper {
    .file-tree-cont {
      padding-top: 1em;

      .function-name-wrapper {
        align-content: center;
        align-items: center;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 0.5em;

        .wrapper-name-branches {
          flex-grow: 1;
          margin-left: 0.7em;

          .function-icon-name-branch,
          .function-icon-no-branch {
            height: 23px;
            width: 23px;
          }

          .function-name {
            font-size: 0.76em;
            margin-left: 0.3em;
          }

        }
        .wrapper-git-commit-log {
          padding-left: 0.2em;
          margin: 0.1em;


          .git-commit-icon-log {
            text-align: center;

            .function-icon-log {
              /*background-color: $primary-blue;*/
              width: 100%;
              height: 24px;
              font-family: BasisGrotesquePro, Arial, sans-serif;
              font-size: 12px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: normal;
              color: var(--white);
            }

            .label-icon-log {
              font-size: 0.55em;
            }
          }
        }

        .icon-branch-wrapper {
          text-align: center;
          padding-left: 0.2em;

          .function-icon-branch {
            background-color: $primary-blue;
            width: 100%;
            height: 24px;
            font-family: BasisGrotesquePro, Arial, sans-serif;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            color: var(--white);
          }

          .label-icon-branch {
            font-size: 0.55em;
          }
        }
      }
    }
  }
}

.func-menu {
  color: #909090;
  font-size: 2em;
  padding: 0.1em;
}

.emptyDirSelect {
  width: 10em;
  height: 10em;
  background-color: $light-blue;
  border-radius: 50%;
  position: relative;  
  margin-left: auto;
  margin-right: auto;
  margin-top: 30%;
  .open-dir-icon {
    color: $primary-blue;
    font-size: 2.5em;
    position: absolute;
    top: 35%;
    left: 38%;
  }
  p {
    color: $black;
    position: absolute;
    top: 110%;
    left: 6%;
    font-size: 1.1em;    
  }
}

.func-list-container {
  padding-top: 0.9em;
}

.file-tree-text {
  display: inline-block;
  font-size: 0.875em;
  line-height: 1.5em;
  /*width: auto;*/
  opacity: 0.8;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: sub;
}

.rename-popover {
  left: 8rem !important;
}


/* START TOOLTIP STYLES */
[tooltip] {
  position: relative;
}

[tooltip]::before,
[tooltip]::after {
  text-transform: none;
  font-size: .9em;
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}
[tooltip]::before {
  content: '';
  border: 5px solid transparent;
  z-index: 1;
}
[tooltip]::after {
  content: attr(tooltip);

  font-family: Helvetica, sans-serif;
  text-align: center;
  min-width: 3em;
  max-width: 21em;
  margin-left: 1.2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: .3ch;
  box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, 0.35);
  background: #333;
  color: #fff;
  z-index: 1;
}

[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

[tooltip='']::before,
[tooltip='']::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  margin-left: 0;
  border-bottom-width: 0;
  border-top-color: #333;
}
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}
[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 4em;
  transform: translate(-50%, -.5em);
}

@keyframes tooltips-vert {
  to {
    opacity: .9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: .9;
    transform: translate(0, -50%);
  }
}

[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

.hljs{display:block;overflow-x:auto;padding:.5em;background:#232323;color:#e6e1dc}
.hljs-comment,.hljs-quote{color:#bc9458;font-style:italic}
.hljs-keyword,.hljs-selector-tag{color:#c26230}
.hljs-string,.hljs-number,.hljs-regexp,.hljs-variable,.hljs-template-variable{color:#a5c261}
.hljs-subst{color:#519f50}
.hljs-tag,.hljs-name{color:#e8bf6a}
.hljs-type{color:#da4939}
.hljs-symbol,.hljs-bullet,.hljs-built_in,.hljs-builtin-name,.hljs-attr,.hljs-link{color:#6d9cbe}
.hljs-params{color:#d0d0ff}
.hljs-attribute{color:#cda869}
.hljs-meta{color:#9b859d}
.hljs-title,.hljs-section{color:#ffc66d}
.hljs-addition{background-color:#144212;color:#e6e1dc;display:inline-block;width:100%}
.hljs-deletion{background-color:#600;color:#e6e1dc;display:inline-block;width:100%}
.hljs-selector-class{color:#9b703f}
.hljs-selector-id{color:#8b98ab}
.hljs-emphasis{font-style:italic}
.hljs-strong{font-weight:bold}
.hljs-link{text-decoration:underline}

.git-console-error {
  background-color: black;
  color: red;
  padding: 1.2em 0.8em 0.1em 1.4em;
  margin-bottom: 0.8em;
}

.next-lines-highlight {
	z-index: 1;
	margin: auto;
	text-align: center;
	background-color: #212529;
	color: #212529;
	border: none;
	padding: 14px 28px;
	font-size: 1em;
	cursor: pointer;
	border-style: solid none solid none;
	border-color: $navy-blue;
}

.next-lines-highlight:hover{
	z-index: 1;
	margin: auto;
	text-align: center;
	background-color: #212529;
	color: $light-blue;
	border: none;
	padding: 14px 28px;
	font-size: 1em;
	cursor: pointer;
	border-style: solid none solid none;
	border-color: $navy-blue;
}
