.habitat-s3-management-container{
    width: 100%;

    .error-display{
        font-size: 0.9em;
        font-weight: bold;
        width: 95%;
        margin: 1em 1em 1.5em 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        p{
          padding: 0;
          margin: 0;
          height: 100%;
        }
      }

      .s3bucket-spinner{
         margin: 0.5em 0 0 0; 
      }

      .select-connected-avaiable{
          display: flex;
          text-transform: uppercase;
          font-size: 0.9em;
          margin: 0.5em 0 1.3em 0;
          color: #8186A5;

          .active-tab{
              color: #1D7AFF;
              border-bottom: 1px solid #1D7AFF;
          }

          p:nth-child(1){
              margin: 0em 0.5em 0 0;
              padding: 0 0 0.2em 0;
          }

          p{
              margin: 0;
              cursor: pointer
          }
      }
}

.container-connected-buckets{
    width: 100%;

    .container-box-s3{
        display: flex;
        padding: 1.5em;
        margin: 0 0 1em 0;
        border: 1px solid #E6E8FF;
        border-radius: 16px;
        justify-content: space-between;
        align-items: center;
    }

    .managed-by-text{
        margin: 0 0.5em 0 0 !important;
        font-weight: bold !important; 
    }

    .button-details{
        button{
            background-color: #EA6F2E;
            border-color: #EA6F2E;
            width: 9em;
        }
    }

    .button-delete-details{
        display: flex;
        align-items: center;
      
        button{
          width: 10em;
          height: 2.5em;
        }
      
        button:nth-child(1){
          background-color: #8186A5;
          border-color: #8186A5;
          margin: 0 0.5em 0 0;
          width: 9em;
        }
      
        button:nth-child(2){
          background-color: #EA6F2E;
          border-color: #EA6F2E;
          width: 9em;
        }
      
    }

    .avatar-repo-author{
        padding: 0.1em 0.5em 0.1em 0;
    }

    .managed-by-repo{
        display: flex;
        align-items: center;
    }

    .icon-storage-box{
        display: flex;
        align-items: center;

        .info-storage-bucket{
            margin: 0 0 0 1em;

            .name-storage-box{
                font-size: 1.09em;
                font-weight: bold;
            }

            .last-updated-text{
                font-size: 0.75em;
                text-transform: uppercase;
                margin: 0.3em 0 0 0;
                color: #8186A5;
                font-weight: bold;
            }

            span{
                color: black;
            
            }
        }

        .img-box-storage{
            background-color: #EB222E;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0.5em 0 0;


            img{
                width: 2em;
                height: 2em;
            }
        }

        p{
            margin: 0;
        }
    }
}

.management-s3-container{
    display: flex;
    margin: 2em 0 0 0;
    justify-content: space-between;


    p{
        font-size: 1.1em;
        font-weight: bold;  
        width: auto;   
    }

    .options-aws{
      width: auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;    
      button{          
        width: 100%;
        margin: 0 0.5em 0 0;
      }
    }    
}

.new-s3-container{
    padding: 1em 0 1em 0;

    .subtitle-s3{
        font-size: 1.1em;
        font-weight: bold;          
    }

    
    display: flex;
    flex-direction: column;

}

.s3-files-container{
    padding: 1em 0 1em 0;

    
    .info-bucket{
        display: flex;
        justify-content: space-between;

        p{
            margin: 0;
        }
    }


    .subtitle-s3{
        font-size: 1.1em;
        font-weight: bold;          
    }

    .file-inspector{
        background-color: #f5f6fa;
        padding: 1em;
        margin: 1em 0 0 0;

        pre{
            margin: 0.3em 0 0 0;
        }
    }

    .text-l{
        margin: 1em 0 0 0;
        font-size: 0.9em;
    }
}

.inputs-new-s3{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    input, select{
        width: 50%;
        margin: 0 0.5em 0.5em 0;
    }

    .btn-newRole{
        font-weight: bold;
    }
}

.list-s3-creds{


    .files-downdrop{
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0 0 0 -0.5em;  

        p{
            margin: 0;
        }

        svg{
            color: #909aff;
            font-size: 0.9em;
            margin: 0 0 0 0.2em;
        }
    }

    .table{
        margin: 1em 0 1.3em 0;
        border: 1px solid #E9ECEF;        
    }


    .table thead tr{
        border-bottom: 1px solid;
        color: white;
        background-color:  #909aff;
    }

    .table thead th{
        padding: 0.2em;
    }


    .table td{
        padding: 0.5em 0 0 0.5em;
    }
}