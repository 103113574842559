@import '../variables/app_layout_vars.scss';

.habitat-main .habitat-main-content {
  display: inline;
}

.help {
  font-size: 22px;
  color: #1d7aff;
}

.new-habitat-btn {
  border-radius: 4px;
  background-color: $primary-blue;
  font-family: BasisGrotesquePro, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background-color: #EA6F2E;
  border: #EA6F2E;
  letter-spacing: 1px;
  width: 15em;
  height: 3em;
  img{
    margin: 0 1em 0 0;
  }
}


.new-habitat-btn:hover{
  background-color: #EA6F2E;
  border: #EA6F2E;
}

.habitat-main-list-item {
  background-color: white;
  height: 100vh;
  padding: 1.5em 2em;
  margin: 0 0 2em 0 !important;

  h5{
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .list-group {
    width: 100%;
  }
}

.habitats-list-buttons{
  display: flex;
  align-items: center;

  button{
    width: 10em;
    height: 2.5em;
  }

  button:nth-child(1){
    background-color: #8186A5;
    border-color: #8186A5;
    margin: 0 0.5em 0 0;
  }

  button:nth-child(2){
    background-color: #EA6F2E;
    border-color: #EA6F2E;
  }

}

.habitat-list-item {
  margin-top: 1.5em;
  border: solid 1px $gray-300;
  border-radius: 4px;
  height: auto;
  border-radius: 16px;
  background-color: $white;
  cursor: pointer;

  .habitat-comp {
    .habitat-list-titles {
      text-align: left;
    }
  }

  .habitat-dots {
    color: #000000;
    opacity: 0.8;
  }
}

.habitat-sect-item{
  margin: 0 1em 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .habitat-quantity{
    background-color: #F0F1F9;
    padding: 1em 2em;
    border-radius: 20px;
    font-size: 0.7em;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 9em;
    text-align: center;
    margin: 0;
  }

  .habitat-list-titles{
    font-weight: bold;
    font-size: 0.7em;
    text-align: center;
    margin: 0.7em 0 0 0;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .check-health{
    display: flex;
    color: #1FC173;
    margin: 0 0em 0 0;
    align-items: center;
    justify-content: center;

    p{
      margin: 0 0 0 0.5em;
      text-align: center;
    }
  }
}


.habitats-list{
  align-items: center;
  justify-content: center;
  flex-direction: center;
  display: flex;
}

.habitat-list-title{
  padding-left: 0em;
  margin-right: 0em;
  width: 100%;
}

.habitat-list-name-functions{
  display: flex;
  width: 60%;

  .habitat-list-title{
    width: 50%;
    display: flex;
    align-items: center;

    div:nth-child(1){
      display: flex;
      align-items: center;
    }

    .habitat-title-date{
      flex-direction: column;
      align-items: flex-start;
      text-align: left;

      .habitat-name{
        font-weight: bold;
        font-size: 1.1em;
        width: 100%;
        margin: 0;
      }

      .last-update-habitat{
        text-transform: uppercase;
        font-size: 0.7em;
        margin: 0.5em 0 -0.5em 0;
        width: 100%;

        span{
          color: #8186A5;
        }

        p{
          width: 100%;
          margin: 0;
        }
      }



      .habitat-created-by{
        width: 100%;
        font-size: 0.9em;
        display: flex;
        align-items: center;

        p{
          margin: 0
        }

        .habitat-created-by-p{
          text-transform: uppercase;
          font-size: 0.8em;
        }

        .name-avatar-habitat{
          display: flex;
          font-weight: bold;
          font-size: 0.8em;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          margin: 0.7em 0 0 0.7em;
          align-items: center;
          background-color: #F0F1F9;
          border-radius: 20px;

            p:nth-child(1){
              width: 2em;
              height: 2em;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #1D7AFF;
              color: white;
              border-radius: 50%;
              margin: 0;
              
            }

              p:nth-child(2){
                padding: 0 1em;
                margin: 0;
            }
        }
      }
    }
  }
}

.habitat-item-container{
  padding: 1.5em 1em;
  display: flex;
  justify-content: space-between;
}

.habitat-list-item:hover {
  box-shadow: 0 12px 12px -10px rgba(13, 25, 63, 0.12);
  border: solid 1px rgba(29, 122, 255, 0.5);
}


.habitat-func-list-item {
  margin-top: 1.5em;
  border: solid 1px $gray-300;
  border-radius: 5px;
  width: 100%;
  height: 5em;
  background-color: $white;
  cursor: pointer;

  .name {
    .git-info-wrapper {
      border-left: 2px solid $gray-300;
    }

    .func-hab-title {
      color: $black;
      font-family: BasisGrotesquePro, Arial, sans-serif;
      font-size: 1em;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
    }

    .habitat-icon-git {
      height: 16px;
      margin: 0 0.5em;
      opacity: 0.6;
      width: 12px;
    }

    .git-info {
      color: $black;
      display: inline-block;
      font-family: BasisGrotesquePro, Arial, sans-serif;
      font-size: 0.75em;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      opacity: 0.48;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 75%;
      white-space: nowrap;
    }
  }

  .hf-icon {
    height: 13px;
    margin-right: 0.5em;
    width: 13px;
  }

  .habitat-function-text {
    color: $black;
    font-family: BasisGrotesquePro, Arial, sans-serif;
    font-size: 0.875em;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
  }

  .h-f-error {
    .habitat-function-text {
      color: #868C9F;
    }
  }
}

.habitat-func-list-item:hover {
  box-shadow: 0 12px 12px -10px rgba(13, 25, 63, 0.12);
  border: solid 1px rgba(29, 122, 255, 0.5);
}


.habitat-drop-down-by {
  font-family: BasisGrotesquePro, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: $primary-blue;
  margin-top: 0.5em;
}

.main-content-left {
  width: 156px;
  height: 25px;
  font-family: BasisGrotesquePro, Arial, sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $black;
  margin-left: 0.3em;
}

.main-content-right {
  float: right;
}


.habitat-container-c{
  display: flex;

  .content-habitat-selected{
    width: 100%;
    height: 100vh;
  }

  .content-habitat-item-selected{
    width: 100%;
    height: 100%;
    padding: 1em 2em 0 0;
  }

  .habitat-menu-right-container{
    width: 20%;
    padding: 0 0 0 2em;
    border-right: 2px solid #F0F1F9;
    margin: 2em 2em 2em 0;

    p{
      margin: 0;
      cursor: pointer;
    }

    p:nth-child(1){
      font-weight: bold;
      font-size: 1.5em;
    }

    p:nth-child(2),
    p:nth-child(3),
    p:nth-child(4),
    p:nth-child(5),
    p:nth-child(6){
      border-bottom: 1px solid #F0F1F9;
      width: 60%;
      padding: 0.7em 0;
    }

    p:nth-child(7){
      width: 60%;
      padding: 0.7em 0 0 0;
    }

    .active-menu-habitat{
      color: #1D7AFF;
      font-weight: bold;
      font-size: 1.1em;
      
    }
  }
}

.habitat-container {
  background-color: white;
  min-height: 100vh;
  margin-left: 0em;
  height: 100vh;
  width: 100%;

  .error-display{
    font-size: 0.9em;
    font-weight: bold;
    width: 20%;
    margin: 1em 1em 1.5em 0;
    display: flex;
    align-items: center;
    
    p{
      padding: 0;
      margin: 0;
      height: 100%;
    }

    p:nth-child(2){
      margin: 0 0 0 1em;
    }
  }

  .spinner-container{
    margin: 0.5em 0 0 0 !important;
  }
}

.habitat-container-settings {
  padding: 0 0 2em 0em;
  min-height: 100vh;
  margin-left: 0em;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.habitat-resource-menu-container{
  margin: 2em 0 0 0;

  .title-sections-menu{
    font-size: 1em;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    margin: 0 2em 0 0;

    p{
      font-size: 1.6em;
    }

    button{
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
      font-size: 0.9em;
      width: 17em;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3em;

      img{
        margin: 0 0.5em 0 0;
      }
    }
  }
}

.habitat-feature-container::-webkit-scrollbar{
  display: none;
}

.habitat-feature-container{
  width: 97%;
  padding: 2em 0 0 2em;
  background-color: white;
  margin: 0 0em 0 0em;
  border: 1px solid #E9ECEF;
  overflow-y: scroll;
}

.habitat-feature-container-api{
  width: 50%;
  padding: 2em 0 0 2em;
  background-color: white;
  margin: 0 0 0 2em;
  border: 1px solid #E9ECEF;
  overflow-y: scroll;
}

.habitat-feature-container-api::-webkit-scrollbar{
  display: none;
}

.api-feature-container{
  width: 46%;
  padding: 2em 0 0 2em;
  background-color: white;
  margin: 0;
  border: 1px solid #E9ECEF;
  overflow-y: scroll;
}

.api-feature-container::-webkit-scrollbar{
  display: none;
}


.habitat-setup-container{
  background-color: white;
  padding: 0em 0 0 0em;
  width: 100%;
}

.habitat-icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3em;
  height: 3em;
  padding: 0.2em;
  border-radius: 50% !important;
  background-color: #1D7AFF;

  img{
    width: 1.5em;
    height: 1.5em;
  }
}


.resources-functions-container{
  display: flex;
  width: 20%;
}

.habitat-fc-title {
  margin-bottom: 0em;
  font-family: BasisGrotesquePro, Arial, sans-serif;
  font-size: 1em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: $black;
}

.no-comps {
  margin-left: 2em;
  margin-top: 1.5em;
  color: #4a4a4a;
}

.lock-icon {
  margin-left: 0.6em;
}

.servs {
  background-color: #e3ecfa;
  color: $black;
  border-radius: 15px;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-top: 1.5em;
  margin-right: 1em;
  margin-left: 1em;
  float: left;

}

.deploy-btn {
  float: right;
  margin-right: 2em;
}

/*
*
* Habitat Single
*
*/

.new-habitat-function {
  float: right;
}

.s3-bucket-save {
  float: right;
}

.s3-bucket-form {
  padding-bottom: 0.5em;
  padding-top: 1em;
}

.s3-bucket-row {
  margin-bottom: 1em;
}

.s3-bucket-instructions {
  padding-bottom: 0.5em;
  padding-left: 0.5em;
}

.s3-bucket-name-input {
  width: 50%;
  margin-left: 0.5em;
}

.new-habitat-service {
  float: right;
  margin-top: -2.5em;
}

.new-habitat-component {
  float: right;
  margin-right: 5%;
  margin-top: -2.5em;
}

.new-habitat-infra {
  float: right;
  margin-right: 5%;
  margin-top: -2.5em;
}

.habitat-component-title {
  display: inline;
}
/*
* Infrastructure
*/
.habitat-infrastructure-items {  
  border: 1px solid #e9ecef;
  border-radius: 5px;    
  background-color: white;
}

.s3-area-habitat {
  height: 15em;
  overflow-y: scroll;
}

.naming-restr {  
  margin: -1em 0 0 -1em;
  font-size: 0.9em;
  font-weight: light !important;

}

.avail-s3-title{
  font-size: 1em;
  font-weight: 600;
  margin-top: 1em;
  margin-left: 0.7em;
}

.infra-block {
  margin-top: 0.8em;
  margin-left: 1em;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  border-radius: 5px;
  width: 8em;
  height: 5em;
  padding: 1em;
  padding-right: 3em;
  border: solid 1px $gray-300;
  background-color: $white;
}

.infra-block:hover {
  box-shadow: 0 12px 12px -10px rgba(13, 25, 63, 0.12);
  border: solid 1px rgba(29, 122, 255, 0.5);
  font-family: BasisGrotesquePro, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: $primary-blue;
}

.infra-text-icon {
  margin-left: 2em;
}

.infra-habitat-icon {
  margin-left: 2.5em;
}

.Rectangle {
  border: solid 1px var(--gray-300);
  background-color: var(--white);
}

.infra-icon {
    display: inline-block;
    width: 1.1em;
    height: 1.1em;
    stroke-width: 0;
    margin-top: -0.2em;
    margin-right: 8em;

    color: #0d193f;
    stroke: #0d193f;
    fill: #0d193f;
}

.habitat-service-icon {
  width: 2.5em;
  margin-top: 1.3em;
  margin-left: 1em;
  border-radius: 4px;
}

.habitat-service-icon:hover {
  width: 2.6em;
}

.habitat-service-wrapper {
  float: left;
  width: 2.6em;
  margin-right: 1em;
}
.habitat-service-wrapper .tooltiptext {
  visibility: hidden;
  background-color: $black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  z-index: 1;
  margin-top: -1em;
  position: absolute;
}

.habitat-service-wrapper:hover .tooltiptext {
  visibility: visible;
  width: 6em;
}

/*
*
* Services Menu
*
*/

.da-menu {
  display: inline-grid;
  position: absolute;
  width: 12em;
  border-radius: 2px;
  box-shadow: 0 12px 12px -10px rgba(13, 25, 63, 0.12);
  border: solid 1px $gray-300;
  background-color: $white;
  z-index: 1;
  margin-top: 1.3em;
}

.add-service-menu {
  display: inline-grid;
  position: absolute;
  margin-top: 1.3em;
  margin-left: 3.6em;
  width: 12em;
  border-radius: 2px;
  box-shadow: 0 12px 12px -10px rgba(13, 25, 63, 0.12);
  border: solid 1px #e9ecef;
  background-color: #ffffff;
  z-index: 1;
}

.add-new-service-icon {
  width: 2.5em;
  height: 2.5em;
  border-radius: 4px;
  background-color: $gray-300;
  position: absolute;
  margin-left: -3em;
}

.menu-service-icon {
  width: 2em;
  height: 2em;
  filter: invert(10%) sepia(127%) saturate(1906%) hue-rotate(203deg) brightness(142%) contrast(148%);
}

.add-new-habitat-text {
  width: 66px;
  height: 17px;
  opacity: 0.8;
  font-family: BasisGrotesquePro, Arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $black;
}

.habitat-add-menu-element {
  float: left;
}

.habitat-add-menu {
  display: inline;  
}

.habitat-add-menu:hover {
  width: 99%;
  padding-left: 1%;
  border-radius: 4px;
  background-color: rgba(0, 123, 255, 0.1);
}

.habitat-select-service {
  width: 165px;
  height: 12em;
  border-radius: 4px;
  box-shadow: 0 12px 12px -10px rgba(13, 25, 63, 0.12);
  border: solid 1px $gray-300;
  background-color: $white;
}

.habitat-select-service-icon {
  width: 2.5em;
  margin-top: 0.3em;
  margin-left: 0.2em;
  border-radius: 4px;
}

.btn-services-menu {
  width: 79px;
  height: 40px;
  border-radius: 4px;
  background-color: $primary-blue;
  font-family: BasisGrotesquePro, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: $white;
}
/*
*
* Service Menu
*/
.habitat-service-configuration {
  width: 90%;
  height: 24px;
  font-family: BasisGrotesquePro, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--black);
  margin-top: 1.5em;
}

.habitat-service-label {
  width: 181px;
  height: 24px;
  font-family: BasisGrotesquePro, Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: $black;
}

.align-h-label {
  margin-top: 0.3em;
  padding-left: 0.5em;
}

.close-it-r {
  float: right;
  margin-right: 1em;
  margin-top: 0.5em;
}

.unordered-list {
  list-style-type: none;
  margin: 0;
  padding: 1em 2em 0 2em;
}

.unordered-list > li {
  margin-bottom: 0.1em;
}

li > .habitat-service-label {
  margin-top: 2em;
}

.sub-menu-inputs {
  width: 100%;
  height: 36px;
  margin-bottom: 0.5em;
  border-radius: 4px;
  border: solid 1px $gray-300;
  background-color: $white;
}

.checkbox-remember {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: solid 1px $gray-300;
  background-color: $white;
  color: $primary-blue;
  margin-top: 0.6em;
}

.service-menu-last {
  margin-top: 1em;
}


.round-bg {
  background-color: $primary-blue;
  border-radius: 15px;
  margin: 0.2em;
  opacity: 0.1;
}

.habitat-right-menu {
  opacity: 0.6;
  font-family: BasisGrotesquePro, Arial, sans-serif;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $black;
  padding: 0.6em 1em 0 1em;
  margin-right: 0.7em;
  float: left;
}

.habitat-right-menu:hover {
  border-radius: 4px;
  padding: 0.6em 1em 0 1em;
  background-color: $white;
  color: $primary-blue;
  cursor: pointer;
}

.empty-habitat-list {
  font-size: 1.5em;
  color: $gray-400;
  text-align: center;
  margin-top: 20%;
}

.habitat-close-settings {

  position: absolute;
  right: 0px;
  padding: 0.2em 0.4em 0.2em 0.4em;
  border: solid 1px $gray-300;
}

.habitat-close-settings:hover {
  cursor: pointer;
}

.settings-container {
  width: 100%;
  padding-left: 1em;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 2.5em;
  border: solid 1px $gray-300;
}

.settings-title-habitat {
  margin-bottom: 2em;
}

.configuration-settings-habitat {
  padding-right: 1.5em;
}

.btn-settings-habitat {

  float: right;
}

.configuration-settings-bottom {
  margin-top: 2em;
}

/*
* TWEAKS
*/

.display-nope {
  display: none;
}

.deploy-btn-bucket {
  float: left;

}

.func-habitat, .global-settings-habitat {
  margin-top: 0.7em;
  margin-right: 0em;
  width: 100%;
  padding-right: 1em;
  display: flex;
  flex-wrap: wrap;
}

.habitat-settings-title {
  margin-left: 1em;
}

.title-settings-habitat {
  font-family: BasisGrotesquePro, Arial, sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-top: 0.3em;
}

.empty-func-list {
  font-size: 1.2em;
  color: $gray-400;
  text-align: center;
  margin-top: 1em;
}

.empty-func-list-dashboard {
  font-size: 1.2em;
  color: $gray-400;
  text-align: center;
  margin-top: 2em;
}

.func-hab-icon {
  font-size: 2.5em;
  float: left;
  color: $navy-blue;
  margin-right: 1em;
  margin-left: 1em;
  margin-top: 0.5em;
}

.func-hab-title {
  font-size: 2em;
  display: block;
  padding-top: 0.5em;
}

.func-hab-rm-icon {
  color: $alert-red;
  font-size: 1.5em;
  position: absolute;
  right: 5%;
  top: 1.3em;
  &:hover {
    font-size: 1.6em;
    top: 1.2em;
  }
}

.func-hab-edit-icon {
  color: $primary-purple;
  font-size: 1.5em;
  position: absolute;
  right: 10%;
  top: 1.3em;
  &:hover {
    font-size: 1.6em;
    top: 1.2em;
  }
}

.pos-rel {
  position: relative;
}

/* Warning Generic Bootstrap Change */
.accordion>.card:first-of-type {
  border-bottom: 1px solid rgba(0,0,0,.125);
}

.title-section-ui-habitats{
  margin: 0 !important;

  .button-create-resource-habitat{
    button{
      background-color: #EA6F2E;
      border-color: #EA6F2E;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
      width: 17em;
      font-size: 0.9em;
      height: 3em;
      margin: 0 1em 0 0;
      display: flex;
      align-items: center;
      justify-content: center;

      img{
        margin: 0 0.5em 0 0;
      }
    }
    
  }
}
/*
* Habitats Sub-Menu
*/
.habitat-sub-menu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sub-menu-side-l{
    display: flex;
    align-items: center;
    font-size: 1.3em;

    span{
      color: #1D7AFF;
    }

    svg{
      color: #102589
    }

    p{
      margin: 0 0 0 0.5em;
      font-weight: bolder;
    }
  }
}

.hab-set-save-btn {  
    color: #007bff;
}

.accordion>.card {
  position: relative;
}

.dep-btn {  
  position: absolute;  
  right: 30px;
  top: -5em;
  padding-right: 2em;
  padding-left: 1.5em;
}

.current-prof-title {
  margin-left: 0.5em;
}
.flex-funcs-title{
  display: flex;
  align-items:center;

  p{
    margin: 0 0em 0 0;
  }
}

.current-prof-val {
  margin-left: 1em;
}

.kor-alerts {
  height: 3.2em;
  width: 95%;
}

/* Habitat dashboard charts */
.metric-selectbox {
  width: fit-content;
  height: fit-content;
  margin: 1em 1em 0em 1em;
  font-family: BasisGrotesquePro, Arial, sans-serif;
  border-radius: 4px;
  font-size: 13px;
}

.metric-chart{
  display: flex;

  .form-control{
    margin: 0 0.5em 1em 0;
  }
}

.habitats-dashboard-functions-title svg{
  margin: 0 0.4em;
  color: #102589;
  font-size: 0.9em;
}

/* Habitat functions list */
.habitats-dashboard-functions{
  align-items: center;
  justify-content: center;
  flex-direction: center;
  display: flex;
  padding: 1em 0 0 0;
  max-width: 7em;

  svg{
    font-size: 0.9em;
    margin: 0 0.3em 0 0;
    
  }
  

  .func-runtime svg{
    color: #1D7AFF;
  }

  .deployed-icon svg{
    color: #909AFF
  }

  .active-icon svg{
    color: #6EF8BD;
  }

  .error-icon svg{
    color:gray;
  }
}

.habitats-dashboard-functions-title{
  margin-right: 15em;
}

/* Habitats log page */
.logs-white-box {
  width: 100%;
	min-height: 39em;
	border: 1px solid #e9ecef;
	border-radius: 4px;
}

.logs-menu {
  width: 100%;
  background-color: $gray-200;
  display: flex;
  padding: 0.5em;
  margin: 0 0 0.5em 0;
  display: flex;
  align-items: center;

  select{
    margin: 0 0.5em 0 0;
  }

  input{
    margin: 0 0.5em 0 0;
  }
}

.logs-selectbox {
  width: fit-content;
  height: 2.5em;
  margin: 0.5em;
  font-family: BasisGrotesquePro, Arial, sans-serif;
  font-size: 13px;
}

.logs-submenu {
  width: 100%;
  height: 1em;
  display: flex;
  background-color: $white;
}

.logs-funcName {
  border: 1px solid #e9ecef;
  font-family: BasisGrotesquePro, Arial, sans-serif;
  font-size: 17px;
}

.logs-item {
  opacity: 0.7;
  border-bottom: 1px solid #e9ecef;
  font-family: BasisGrotesquePro, Arial, sans-serif;
  font-size: 14px;
}

.resources-report-container{
  .report-container{
    display: flex;
    
    div{
      background-color: white;
      height: 40em;
      margin: 0 0em 0 0;
    }

    .lambdas-box,
    .dynamos-box,
    .builds-box,
    .builds-box,
    .buckets-box,
    .api-box,
    .cognito-box,
    .sites-box{
      width: 3em !important;
      height: 3em !important;
      background-color: #EA6F2E;
      color: white;
      padding: 0.5em;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      img{
        width: 2em;
        height: 2em;
      }
    }

    .button-view-details{
      button{
        background-color: #EA6F2E;
        border-color: #EA6F2E;
        width: 10em;
        display: flex;
        align-items: center;
        justify-content: center;
        
        img{
          margin: 0 0.3em 0 0;
        }
      }
      
    }

    .name-updated-at{
      p:nth-child(1){
        font-size: 1.3em;
        font-weight: bold;
      }

      p:nth-child(2){
        font-size: 0.86em;
        text-transform: uppercase;
        color: #8186A5;
        
        span{
          font-weight: bold;
          color: black;
        }
      }
    }

    .buckets-box{
      background-color: #EB222E;
    }

    .dynamos-box{
      background-color: #1D7AFF;
    }

    .builds-box{
      background-color: #909aff;
    }

    .api-box{
      background-color: #1FC173;
    }

    .sites-box{
      background-color: #ff6701;
    }

    .cognito-box{
      background-color: #4C589E;
    }

    .sites-box{
      background-color: #FAB735;
    }

    .resources-list-container{
      padding: 1em 0 0 0;
      width: 100%;

      .title-resources{
        color: #c7c7c7;
        font-weight: bold;
      }

      .resource-box{
        border: 1px solid #e9ecef;
        width: 100%;
        height: auto;
        font-size: 0.9em;
        display: flex;
        justify-content: space-between;
        padding: 1.5em 1.5em;
        align-items: center;
        margin: 0 0 1em 0;
        border-radius: 16px;

        
        div{
          height: auto;
        }

        .icon-resource{
          display: flex;
          align-items: center;

          div{
            margin: 0;
            width: auto;
            margin: 0 1em 0 0;
          }
        }

        p{
          margin: 0;
        }

        .number-resource{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 2em;
          height: 2em;
          text-align: center;
          border-radius: 50%;
          font-weight: bold;
          margin: 0;

          p:nth-child(1){
            background-color: #F0F1F9;
            padding: 1em 2em;
            border-radius: 20px;
            font-size: 0.7em;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 1px;
            width: 9em;
            text-align: center;
          }
          
          p:nth-child(2){
            font-weight: bold;
            font-size: 0.7em;
            text-align: center;
            margin: 0.7em 0 0 0;
            text-transform: uppercase;
            letter-spacing: 1px;
          }
        }
      }
    }



  }
}

.habitat-monitoring-menu-container{
  display: flex;
  margin: 0 0 1em 0;
  p{
    margin: 0;
    font-size: 1em;
    font-weight: bold;
    margin: 0 1em 0 0;
    cursor: pointer;
  }

  p:hover{
    border-bottom: 3px solid #909aff;
  }

  .no-active-monitoring{
    color: #c7c7c7
  }

  .active-monitoring{
    border-bottom: 3px solid #909aff;
  }
}

/* Habitat monitor page */
.selecbox-section {
  display: flex;
  overflow: hidden;
  float: right;
  padding-right: 0.5em;
  width: 30%;

  select{
    margin: 0 1em 0.5em 0;
    width: 50%;
  }
}

.metric-container {
  height: 407px;
	min-height: 30em;
  margin-top: 3.5em;

  .metric-1{
    width: 49%;
  }

  .monitoring-grey-box{
    width: 98;
  }
}

.chart-container {
  
	border: 1px solid #e9ecef;
  border-radius: 4px;
  margin: 0.5em;
}

.cred-row {
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 1em;
  padding-bottom: 1em;
}

.verified-col-hab {
  margin-top: 0.5em;
}

.monitoring-grey-box {
  min-height: 90%;
	border: 1px solid #e9ecef;
	border-radius: 4px;
	margin: 1rem;
	background-color: $gray-200;
}

.habitat-top-menu {
    padding: 0.5em 1em 0.5em 1em;
    color: #0d193fcf;
    float: left;
}
  
.habitat-top-menu:hover {
    color: $primary-blue;
    text-decoration: none;
}

.top-menu-icons {
    margin-right: 0.8em;
    font-size: 0.85em;
}

.is-active {
    background-color: #ffffff;
    color: $primary-blue;
    border-radius: 5px;
}
.top-menu-icons {
    margin-right: 0.8em;
    font-size: 0.85em;
}

.top-menu-title {
    font-family: BasisGrotesquePro, Arial, sans-serif;
    font-size: 0.85em;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.sub-menu-side-r {
    margin-top: 0.3em;
}

.nav-title-r {
    float: right;
    font-size: 1.1em;
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
}

.creds-hab-settings {
    margin-top: 1em;
}

.newCredBtn {
    margin-bottom: 1em;
    float: right;
}

.habitat-container::-webkit-scrollbar{
  display: none;
}

.list-group{
  margin: 0 0 7em 0;
}

.funcs-dashboard{
  width: 100%;
}

.title-funcs-dashboard{
  font-size: 1.04em;
  font-weight: bold;
  width: 100% !important;
}

.resources-container-dashboard{
  height: 50%;
}

.button-container-habitat{
  display: flex !important;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-end;

  button{
    margin: 0 0.5em 0 0;
  }

  svg{
    color: #909aff;
    margin: 0 0em 0 0.2em;
  }
}

.question-mark-documentation-habitat{

  svg{
    color: #909aff;
    margin: 0 1.3em 0 0.2em;
  }
}

.question-mark-documentation{
  width: 100%;

  svg{
    color: #909aff;
    margin: 0 1.3em 0 0.2em;
  }
}

.funcs-dashboard-container{
  height: 50%;
}