.habitat-funcs-management-container{
    width: 100%;

    .error-display{
        font-size: 0.9em;
        font-weight: bold;
        width: 95%;
        margin: 1em 1em 1.5em 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        p{
          padding: 0;
          margin: 0;
          height: 100%;
        }
      }
}

.box-feature-item{
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 0 0.5em 0 0;

    div{
        background-color: #F0F1F9;
        padding: 1em 2em;
        border-radius: 20px;
        font-size: 0.7em;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
        width: 10em;
        text-align: center;
        margin: 0 0 0 0.8em;
    }

    .health-check{
       color: #1FC173; 
    }

    p{
        margin:0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.8em;
        text-align: center;
        margin: 0.7em 0 0 0;
        text-transform: uppercase;
        letter-spacing: 1px;

        span{
            margin: 0 0 0 -1em;
        }
    }
}

.funcs-habitat-spinner{
    margin: 0.5em 0 0.5em 0;
}

.title-setup-management-funcs{
    display: flex;
    align-items: center;
    padding: 0 0 1em 0;

    h5{
        margin: 0;
        font-weight: bold;
    }
}

.funcs-setup-box{
    display: flex; 
    border: 1px solid #E9ECEF;
    border-radius: 16px;
    padding: 1.5em;
    font-size: 0.92em;
    margin: 1em 0 1em 0;
    cursor: pointer;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .button-view-details{
        button{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 10em;
            height: 3em;
            background-color: #8186A5;
            border-color: #8186A5;
            font-size: 1em;

            img{
                margin: 0 0.5em 0 0;
            }
        }
    }

    .func-name-author{
        margin: 0 0 0 1em;

        .managed-by-text{
            font-size: 0.8em !important;

        }

        
        .name-author{
            font-size: 1.1em !important;
        }

        .name-func-box{
            font-size: 1.2em;
        }

        .last-update-func{
            font-size: 0.8em;
            text-transform: uppercase;
            margin: 0.3em 0 0 0;
            color: #8186A5;

            span{
                color: black;
            }
        }
    }
}

.box-feature-name-funcs{
    display: flex;
    font-weight: bold;
    align-items: center;
    width: 30%;

    p{
        margin: 0;
    }

}

.text-feature-items{
    width: 30%;
    display: flex;
}

.title-setup-w-new{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E9ECEF;
}

.new-function-btn-setup{
    color: #1d7aff;
    cursor: pointer;
    font-weight: bold;
}

.new-function-container{
    padding: 1em 0 0em 0;
    height: 85%;

    .inputs-new-aws-credential-func{
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 1em;

        .text-modal-1{
            font-size: 1em !important;

        }

        select{
            margin: 0;
            background-color: #F0F1F9;
            border-radius: 4px;
            border: none;
            box-shadow: none;
            width: 80% !important;
            font-size: 0.95em;
        }

        input{
            margin: 0;
            background-color: #F0F1F9;
            border-radius: 4px;
            border: none;
            box-shadow: none;
            width: 80% !important;
            font-size: 0.95em;
        }

        p{
            margin: 0 !important;
            width: 20%;
            font-size: 1em;
        }
    }
    
    display: flex;
    flex-direction: column;
}

.box-feature-item{
    button{
        width: 100%;
    }
}

.new-function-setup{
    display: none;
}

.new-function-show{
    display: flex;
}