* {
  margin: 0;
  padding: 0;
}

.avatar-name{
  display: flex;
  align-items: center;
  justify-content: space-between;

  p{
    margin: 0 1em 0 0;
    font-size: 0.9em;
  }

  .photo-avatar{
    p{
      margin: 0;
    }
  }
}

.avatar-logout{
  display: flex;
}

.logout-img{
  width: 2.2em;
  height: 2.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F0F1F9;
  border: 1px solid #F0F1F9;
  border-radius: 50%;
  margin: 0 0.5em 0 0;
  cursor: pointer;

  img{
    width: 18px !important;
  }
}

#mobile-tablet-main{
  width: 100%;
  height: 100%;
  background-color: #F0F1F9;
  display: flex;
  position: absolute;
}

.header-kor-mobile{
  border-bottom: 2px solid #E6E8FF;
  margin: 0 0 2em 0;
  background-color: white;
  padding: 1em 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img{
    width: 1.8em;
    height: 1.8em;
  }
}

.message-mobile-container{
  background-color: white;
  padding: 2em 1.1em;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;


  h1{
    margin: 1em 0 0.3em 0;
    font-weight: bold;
  }

  a{
    text-decoration: none;
  }

  button{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EA6F2E;
    border-color: #EA6F2E;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 0.9em;
    width: 17em;
  }

  button:hover{
    background-color: #EA6F2E;
    border-color: #EA6F2E;
  }
}
  
.mobile-tablet-container{
  font-family: BasisGrotesquePro, Arial, sans-serif;
  text-align: center;
  width: 100%;
  height: auto;
}

.log-out-button{
  color: #2f7bf7;
  font-weight: bold;
  font-size: 0.9em;
  margin: 1em 0 0 0;
  text-decoration: underline;
  cursor: pointer;
}

.mobile-tablet-message{
  text-align: center;
  margin-top: 3rem;
  -webkit-box-sizing: border-box;  /*webkit*/
  -moz-box-sizing: border-box;  /*Firefox*/
  box-sizing: border-box;
}

#suggetion-btn{
  font-weight: bold;
  width: 10rem;
  display: inline-block;
  margin: 0 auto; 
  position: relative;
  font-size: 0.9em;
}


#suggetion-btn:hover{
  border: none;
  background-color:  #005b8f;
}

.telescope{
  width: 11vw;
  position: absolute;
  margin-top: 2vh;
  margin-left: 5vw;
}

@media screen and (max-width: 480px) { 
  .mobile-tablet-container{
    width: 100vw;
    height: auto;
    font-size: 0.9em;
    padding: 0em;
     }
  .mobile-tablet-message{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

