.habitat-ctn {
  background: #FFFFFF;
  padding: 0px;
  width: 100%;
}

.tab {
  color: #8186A5;
  cursor: pointer;
}


.box-modal-options-container-small-right{
.active {
  border-bottom: 1px solid #1D7AFF;
  color: #1D7AFF;
  width: 50px;
}
}

.btn-grey {
  background-color: #8186A5;
  border-color: #8186A5;
  color: #FBFBFD;
}

.btn-grey:hover {
  background-color: #6b6f88;
  border-color: #6b6f88;
  color: #FBFBFD;
}

.buttons-bottom {
  margin-top: 40%;
}

.text-small {
  font-size: 10px;
}

.text-bold {
  font-weight: bold;
}

.rounded-kor-repo-1{
  border: 1px solid #E6E8FF;
  border-radius: 16px;
  margin: 1.2em 0em 1.2em 0em;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding: 1.5em 2em;
}