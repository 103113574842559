.habitat-auth-container{
    height: 100vh;
    display: flex;
    padding: 0em 0 0 0;

    .habitat-auth-list-container{
        background-color: white;
        width:100%;
        height: 90%;
        margin: 0 0 0 0;
    }

    .button-delete-details{
        button{
            background-color: #EA6F2E;
            border-color: #EA6F2E;
            width: 9em;
        }
    }


    .auth-box{
        display: flex;
        border: 1px solid #E9ECEF;
        border-radius: 16px;
        padding: 1.5em;
        font-size: 0.92em;
        margin: 1em 0 1em 0;
        cursor: pointer;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        .box-feature-name-auth{
            display: flex;
            align-items: center;
            
            .box-feature-name-icon{
                width: 3em;
                height: 3em;
                background-color: #4C589E;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                margin: 0 0.5em 0 0;
            }

            .auth-name-author{
                margin: 0 0 0 1em;
                p{
                    margin: 0;
                }
                .name-auth-box{
                    font-size: 1.2em;
                    font-weight: bold;
                }   
                
                .last-update-auth{
                    font-size: 0.8em;
                    text-transform: uppercase;
                    margin: 0.3em 0 0 0;
                    color: #8186A5;
                    font-weight: bold;

                    span{
                        color: black;
                    }
                }

                .managed-by-text{
                    font-size: 0.8em !important;
                    margin: 0 0.5em 0 0;
                }
            }
        }
    }

    .habitat-auth-management{
        background-color: white;
        width: 40%;
        height: 65%;
        border: 1px solid #E9ECEF; 
        padding: 2em;


        .info-user-selected{
            border-bottom: 1px solid #E9ECEF;
            padding: 0 0 1em 0;

            p{
                margin: 0;
            }
        }

        .subtitle-auth{
            margin: 1em 0 0 0;
            font-weight: bold;
            font-size: 1.08em;
        }

    }

    .new-pool-manage{
        border-top: 1px solid #E9ECEF;
        margin: 1.8em 0 0 0;
        padding: 1.3em 0 0 0;

        p{
            font-size: 1.08em;
            font-weight: bold;
        }
    }

    .input-button-auth{
        display: flex;
        align-items: center;

        button{
            margin: -0.5em 0 0 0;
        }


        .inputs-new-aws-credential{
            width:70%;

            input{
                width: 90%;
            }
        }
    }

    .button-auth{
        button:nth-child(1){
            margin: 0 0.5em 0 0;
        }
    }

    .title-habitat-costs{
        font-weight: bold;
        font-size: 1.1em;
    }

    .list-habitat-auth{
        margin: 0;
    }

    .table{
        margin: 0em 0 0 0;
        border: 1px solid #E9ECEF;
    }


    .table thead tr{
        border-bottom: 1px solid;
        color: white;
        background-color:  #909aff;
    }

    .table thead th{
        padding: 0.5em 0 0.5em 0.7em;
    }


    .table td{
        padding: 0.5em 0.7em;
    }

    .show-users-auth{
        svg{
            color: #909aff;
        }
    }

    .button-reset{
        color: #909aff;
        border-color: #909aff;
    }

    .button-reset:hover{
        background-color:  #909aff;
        color: white;
    }

    .user-management{
        margin: 1em 0 0 0;

        button{
            margin: 0 0.5em 0 0;
        }
    }

    .management-user{
        text-align: center;
    }

    .title-users-list{
        margin: 2em 0 0 0;
        display: flex;
        justify-content: space-between;

        button{
            width: 6.5em;
            height: 2.5em;
        }
    }

    .title-auth{
        font-size: 1.1em;
        display: flex;
        justify-content: space-between;

        button{
            width: 6.5em;
            height: 2.5em;
        }
    }

    .display-none-pool,
    .display-none-reset
    {
        display: none;
    }

    .empty-section-auth{
        color: #c7cfd1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 99.9%;
        padding: 1em 0 0 0;
        border: 1px solid #E9ECEF;
    }

    .empty-section-auth-management{
        color: #c7cfd1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 99.9%;
        padding: 1em 0 0 0;
    }

    .text-success-1{
        color: #28a745!important;
        font-size: 0.9em;
    }

    .text-success-1-management{
        color: #28a745!important;
    }

    .text-danger-management{
        color: #dc3545!important;
    }

    .text-danger{
        font-size: 0.9em
    }

    .text-success{
        font-size: 0.9em;
        font-weight: bold;
        margin: 0.5em 0 0 0;
    }

    .auth-habitat-spinner{
        margin: 2em;
    }

}

.display-none-disable-user-modal,
.display-none-modal-remove
{
    display: none;
}

.check-enable-no-domain{
    display: flex;
    align-items: center;
    margin: 1em 0 0 0;

    p{
        font-size: 1em;
        font-weight: normal;
        margin: 0 0 0 0.5em;
    }
}

.button-container-create-site{
    margin: 0.5em 0 0 0;
}