.habitatdb-container{
    height: 100vh;
    display: flex;

    .feature-info-habitatdb,
    .feature-management-habitatdb{
        width: 100%;
        background-color: white;
        height: 90vh;
        padding: 0em 0 0 0em;
    }

    .feature-info-habitatdb{
        margin: 0 0em 0 0;
    }

    .feature-management-habitatdb{
        width: 50%;
    }

    .title-feature-habitatdb{
        display: flex;
        align-items: center;

        .inactive-feature{
            color: #8186A5;
        }

        .active-feature{
            color: #1D7AFF;
            border-bottom: 1px solid #1D7AFF;
        }

        p{
            margin: 0.5em 1em 0 0;
            font-size: 0.9em;
            cursor: pointer;
            text-transform: uppercase;
        }
    }

    .dynamo-management-title{
        margin: 2em 0 0 0;
        font-weight: bold;
        font-size: 1.08em
    }

    .dynamo-management-sub{
        font-weight: bold;
        margin: 0 0 0.5em 0em;
    }


    .form-container-dynamo{
        margin: 1em 0 0 0;
    }

    .new-dynamo-input{
        display: flex;
        justify-content: space-between;

        input{
            width: 70%;
        }

        button{
            font-weight: bold;
            height: 2.5em;
            font-size: 0.9em
        }
    }

    .button-add-fields{
        button{
            font-weight: bold;
            height: 2.5em;
            font-size: 0.9em 
        }
    }

    .new-function-btn-setup{
        width: 95%;
        display: flex;
        justify-content: flex-end;
        margin: 1em 0 0 0;
        padding: 0 0 1em 0;

        p{
            margin: 0;
        }
    }

    .attr-container{
        display: flex;

        div{
            width: 35%;
            margin: 0 1em 0 0;
        }
    }

    .display-none{
        display:none
    }
    
    .container-dynamos-box{
        margin: 1em 0 0 0;

        .funcs-setup-box{
            width: 100%;

            .box-feature-name-icon{
                background-color: #1D7AFF;
            }
            
            .box-feature-item{
                width: 50%;
                justify-content: flex-end;
                align-items: flex-end;
            }
            
            button{
                width: 7em;
            }
        }
    }

}

.box-feature-item-button{
    button{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10em;
        height: 3em;
        background-color: #8186A5;
        border-color: #8186A5;
        font-size: 1em;
    }
}

.aurora-db-management{
    .new-aurora-input{
        display: flex;
        align-items: center;
        margin: 1em 0 0 0;

        input{
            width: 92%;
        }
        
        button{
            font-size: 0.9em;
            font-weight: bold;
        }
    }

    .new-aurora-input-2{
        display: flex;
        align-items: center;
        margin: 1em 0 0 0;

        input{
            width: 45%;
            margin: 0 1em 0 0;
        }

        select{
            width: 45%;
        }
    }

    .aurora-inputs{
        button{
            font-size: 0.9em;
            font-weight: bold;
            margin: 0.9em 0 0 0;
        }
    }

    .spinner-container{
        margin: 1em 0 0 0;
    }
}

.error-display{
    margin: 1em 0 0 0;
    font-weight: bold;
    font-size: 0.9em;
}