$screen-sm-min: 576px;
$screen-md-min: 992px;
$screen-lg-min: 1000px;
$screen-xl-min: 1600px;

@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
 }

@mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
}

@mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}

@mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}

.habitat-container-deployment{
    display: flex;
    height: 100vh;
    padding: 0 0 2em 0em;
}

.habitat-all-funcs-deployment,
.habitat-history-deployment{
    background-color: white;
    padding: 2em 0 0 1.5em;
    width: 100%;
    padding: 0em 0 0 0em;
}

.sites-title-releases{
    margin: 0 0 0 1em !important;
}

.funcs-title-releases,
.sites-title-releases{
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0.9em;

    p{
        font-size: 1em !important;
    }
}

.active-funcs{
    color: #1d7aff;
    border-bottom: 1px solid #1d7aff;
}

.active-sites{
    color: #1d7aff;
    border-bottom: 1px solid #1d7aff;
}

.inactive{
    color: #c7c7c7
}

.question-mark-documentation-releases{
    margin: 0 1em 0 0;

    svg{
        color: #909aff
    }
}

.react-codemirror2{
    position: static !important;
    width: 100%;
    height: 100% !important;
    overflow: scroll;

    @include xl {
        width: 100% !important;
    }

    @include lg {
        width: 25em;
    }

    @include md {
        width: 20em;
    }
}

.react-codemirror2::-webkit-scrollbar{
    display: none;
  }

.enable-ci-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5em 0 0 0;
    border: 1px solid #E9ECEF;
    border-radius: 5px;
    padding: 0.5em;

        
    p{
        margin: 0;
    }
}

.buildspec-file{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5em 0 0 0;
    border: 1px solid #E9ECEF;
    border-radius: 5px;
    padding: 0.5em;
    
    p{
        margin: 0;
    }
}

.invalidations-container{
    display: flex;
    flex-direction: column;
    margin: 0.5em 0 0 0;
    border: 1px solid #E9ECEF;
    border-radius: 5px;
    padding: 0.5em;
    
    p{
        margin: 0;
    } 

    .button-validations{
        font-size: 0.9em;
        margin: 0.5em 0 0 0;
    }

    .text-area-invalidations{
        margin: 0.5em 0 0 0;
        height: 10em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0.5em 0 0 0;
        border: 1px solid #E9ECEF;
        border-radius: 5px;
        padding: 0.5em;
    }


    .table-invalidations{
        font-size: 0.9em;

        .status-completed svg{
            color: #a5fade;
        }

        .status-inprogress svg{
            color: #ffc107;
        }

        .show-invalidation svg{
            color: #909aff;
        }

    }

    .table-invalidations thead th{     
            width: auto;
    }

    .save-invalidations-button{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}

.invalidation-selected-container{
    background-color: #f5f6fa;
    padding: 0.5em;
    margin: 0.5em 0 0 0;
    font-size: 0.9em;
}

.invalidation-container{
    textarea{
        width: 100%;
    }
}

.builds-container-left{
    background-color: black;
}

.habitat-settings-container-feature{
    display: flex;
    background-color: #f5f6fa;
    min-height: 100vh;
    padding: 0 0 2em 0em;

    .habitat-settings-left-container{
        background-color: white;
        padding: 2em 0 0 1.5em;
        width: 45%;
        border: 1px solid #E9ECEF;
        padding: 2em 0 0 2em; 
       
    }

    .habitat-settings-right-container{
        background-color: white;
        padding: 2em 0 0 1.5em;
        width: 50%;
        border: 1px solid #E9ECEF;
        padding: 2em 0 0 2em;
        margin: 0 0 0 2em;
    }
}


.habitat-history-deployment{
    margin: 0 0 0 2em;
    width: 50%;
}

.functions-deploy-container{
    height: 100%;

    .error-display{
        font-size: 0.9em;
        font-weight: bold;
        width: 95%;
        margin: 1em 1em 1.5em 0em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        p{
          padding: 0;
          margin: 0;
          height: 100%;
        }
      }
}

.all-deployment-items{
    margin: 1.5em 0 0 0;
}


.templates-releases-container{
    margin: 2em 0 0 0;
}

.title-deployments{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0em 0 0em 0;

    p{
        margin: 0;
        font-size: 0.9em;
    }
}

.box-feature-name-icon-templates-deploy{
    width: 3em;
    height: 3em;
    background-color: #FAB735;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 0.5em 0 0;

    img{
        width: 2em;
        height: 2em;
    }
}

.box-func-deploy{
    width: 100%;
    padding: 1.5em !important;
    border: 1px solid #E9ECEF;
    border-radius: 16px;
    display: flex;
    justify-content: center;

    .box-feature-name{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .func-name-date-deploy{
        margin: 0 0 0 1em;

        .name-func-box{
            font-size: 1.1em;
            margin: 0 0 0.3em 0;
        }
        .last-update-func{
            font-size: 0.75em;
            text-transform: uppercase;
            margin: 0em 0 0 0;
            color: #8186A5;

            span{
                color: black;
                margin: 0 0.3em 0 0;
            }
        }
    }

}

.box-func-deploy{
    display: flex;
    flex-direction: column;

    table{
        margin: 1rem 0 0 0;
        border: 1px solid #E9ECEF;

        p{
            margin: 0;
        }

        thead{
            background-color: #f5f6fa;

            th{
                padding: 0.4em 1rem;
                width: 50%;
                text-align: center;
            }
        }

        tbody{
            tr{
                td{
                    border-left: 1px solid #E9ECEF
                }
            }
        }
    }

    .runtime-deploy{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 1em 0 0 0;
    
        p{
            margin: 0;
        }
    }

    .enabled-cicd{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: .7em;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-align: center;

        div{
            background-color: #f0f1f9;
            padding: 1em 2em;
            border-radius: 20px;
            width: 12em;
            margin: 0;
            margin-bottom: .7em;
            color: #1fc173;
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                margin: 0 0.5em 0 0;
            }
        }
    }

    .enabled-cicd-disabled{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: .7em;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-align: center;

        div{
            background-color: #f0f1f9;
            padding: 1em 2em;
            border-radius: 20px;
            width: 12em;
            margin: 0;
            margin-bottom: .7em;
            color: #2A3158;
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                margin: 0 0.5em 0 0;
            }
        }
    }

}

.box-feature-name-container{
    display: flex;
    align-items: center;
}

.CodeMirror{
    height: 100% !important ;
    margin: 0.5em 0 0 0;
}

.CodeMirror::-webkit-scrollbar {
    display: none !important;
}
  

.CodeMirror-sizer{
    min-width: 0 !important;
}

.check-enable-build{
    width: auto;
    height: 1em;
    padding: 0;

    .form-check-input{
        margin: -0.1em 0 0 -1.6em;
        position: normal;
        width: 1.3em;
        height: 1.3em;
        border: 1px solid #017bff;
    }

}

.check-enable-build-1{
    width: auto;
    height: 1em;
    padding: 0;

    .form-check-input{
        margin: -0.1em 0 0 0em;
        position: normal;
        width: 1.3em;
        height: 1.3em;
        border: 1px solid #017bff;
    }
}

.margin-left p{
    margin: 0 0 0 1.8em;
}

.check-enable-build-container{
    display: flex;
    color: #017bff;
    align-items: center;
}



.box-func-deployed{
    border: 1px solid #E9ECEF;
    width: 100%;
    margin: 1em 0 0 0;
    
    p{
        margin: 0;
    }

    .name-func-deployed{
        display: flex;
        justify-content: space-between;
        color: white;
        font-weight: bold;
        background-color: #909aff;
        padding: 0.3em 1em;
    }

    .info-func-deployed{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1em;
        background-color: white;

        .fail-color{
            color: #ff6a5d;
            font-size: 0.9em;
        }
    
        .success-color{
            color: #a5fade;
            font-size: 0.9em;
        }
    }

}

.info-func-deployed-box{
    text-align: center;
}

.id-deployed-func{
    padding: 1em 1em;
    background-color: white;
}

.logs-text{
    color: #4f8dff;
    cursor: pointer;
    font-size: 0.8em
}

.funcs-deployed-container::-webkit-scrollbar,
.all-deployment-items::-webkit-scrollbar,
.deploy-items-container::-webkit-scrollbar{
    display:none
}

.deploy-items-container{
    height: 34em;
    overflow-y: scroll;
}

.funcs-deployed-container{
    width: 100%;
    background-color: #f5f6fa;
    padding: 1em;
    margin: 0 0 2em 0;
    height: 85%;
    border: 1px solid #E9ECEF;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.all-deployment-items{
    height: 80%;
    overflow-y: scroll;
}

.btn-search-deployed{
        font-weight: 700;
}


.search-funcs-deployed{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 1em 0 0 0;

    .btn-search-deployed{
        font-weight: bold;
    }

    p{
        margin: 0;
    }

    input{
        width: 35%;
        margin: 0 1em;
    }

    .dates-from-to{
        display: flex;
        align-items: center;
        margin: 0 0 1em 0;
    }
}

.filter-deployed{
    background-color: white;
    border: 1px solid #E9ECEF;
    padding: 0.5em;
}

.filter-deployed{
    display: flex;
    justify-content: space-between;
    align-items: center;

    button{
        height: 2.2em;
        width: 20%;
    }

    input{
        width: 30%;
        margin: 0 0.5em 0 0;
    }

    select{
        width: 30%;
        margin: 0 0.5em 0 0;
    }
}

.logs-funcs-deploy{
    background-color: white;
    padding: 0 0 1em 0;
    display: flex;
    flex-direction: column;
}

.log-deploy-item{
    background-color: white;
    padding: 0 1em;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 100%;

    div:nth-child(1){
        display: flex;
        width: 100%;
        justify-content: space-between;
        border-top: 1px solid #E9ECEF;
        padding: 0.5em 0 0 0;
    }

    .log-func-all{
        background-color:#f5f6fa;
        color: #fd796e;
        margin: 0.5em 0 0em 0;
        padding: 0.5em;
        display: flex;
        height: 5em;
        overflow-wrap: normal;
        width: 100%;

        pre{
            color: #fd796e; 
            overflow-wrap: normal;
        }
    }
}
.test-function-deployment{
    background-color: white;
    padding: 1em;

    .form-group{
        width: 100%;
        margin: 0.5em 0 0 0;

        .form-label,
        .form-control{
            font-size: 0.9em;
        }
    }

    button{
        font-size: 0.9em;
    }
}

.display-log{
    display: none;
}

.display-log-show{
    display: flex;
}

.display-all-log{
    display: none !important;
}

.display-all-log-show{
    display: flex;
}

.display-invoke-deploy{
   display: none; 
}

.display-invoke-deploy-show{
    display: block;
}

.pagination-deployment-history{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1em 0 0 0;

    .page-item{
        padding: 5px;
    }

    .page-link{
        padding: 0.3rem 0.55rem;
        font-size: 0.7em;
    }
}

.number-items-container{
    display: flex;
}

.results-deployment{
    margin: 1em 0 0 0;

    p{
        margin: 0;
        font-size: 0.9em;
    }
}

.info-func-deploy{
    display: none;
}

.info-func-deploy-show{
    display: normal;
}

.opacity-display{
   opacity: 0;
    height: 0;
}

.opacity-display-show{
    height: 30em;
    width: 100%;
    background-color:#f5f5f5;
    margin: 0.5em 0 0 0;

}

.builds-container-left{
    .empty-div{
        height: 100vh;
        margin: -4em 0 0 0;
    }
}

.deployments-container-history{
    height: 100%;
    margin: 1em 0 0 0;
    .error-display{
        font-size: 0.9em;
        font-weight: bold;
        width: 95%;
        margin: 1em 1em 1.5em 0em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        p{
          padding: 0;
          margin: 0;
          height: 100%;
        }
    }
}

.button-deploy-releases{
   background-color: #EA6F2E;
   border-color: #EA6F2E;
   width: 10em;
   display: flex;
   align-items: center;
   justify-content: center;
   height: 2.8em;
   margin: 0 0.5em 0 0;

   img{
       margin: 0 0.5em 0 0;
   }

}

.button-deploy-releases-details{
    background-color: #EA6F2E;
    border-color: #EA6F2E;
    width: 11em;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.8em;
 
    img{
        margin: 0 0.5em 0 0;
    }
 
}