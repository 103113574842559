.box-feature{
   display: flex; 
   justify-content: space-between;
   width: 12em;
   border: 1px solid #E6E8FF;
   border-radius: 5px;
   padding: 1em 0.5em;
   font-size: 0.92em;
   margin: 0 0.5em 1em 0;
   cursor: pointer;
   align-items: center;
}

.box-feature:hover{
    border: 1px solid  #909aff;
}

.box-feature-selected{
    border: 1px solid  #909aff;
}

.databases-selected{
    border: 1px solid  #909aff;
}

.auth-selected{
    border: 1px solid  #909aff;
}

.habitat-selected{
    border: 1px solid  #909aff;
}

.instance-selected{
    border: 1px solid  #909aff;
}

.apis-selected{
    border: 1px solid  #909aff;
}


.roles-selected{
    border: 1px solid  #909aff;
}

.funcs-selected{
    border: 1px solid  #909aff;
}

.s3-selected{
    border: 1px solid  #909aff;
}

.dynamo-selected {
    border: 1px solid  #909aff;
}

.templates-selected {
    border: 1px solid  #909aff;
}

.cognito-selected {
    border: 1px solid  #909aff;
}

.api-selected {
    border: 1px solid  #909aff;
}


.box-feature-name{
    display: flex;
    font-weight: bold;
    align-items: center;

    p{
        margin: 0;
    }


}

.box-feature-name-icon{
    width: 3em;
    height: 3em;
    background-color: #EA6F2E;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 0.5em 0 0;

    img{
        width: 2em;
        height: 2em;

    }
}

.box-feature-name-icon-funcs{
    width: 2em;
    height: 2em;
    background-color: #EA6F2E;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 0.5em 0 0; 
}

.box-feature-name-icon-templates{
    width: 2em;
    height: 2em;
    background-color:#FAB735;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 0.5em 0 0;  

    img{
        width: 1.8em;
        height: 1.8em;
    }
}

.box-feature-name-icon-apis{
    width: 2em;
    height: 2em;
    background-color:#1FC173;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 0.5em 0 0;  

    img{
        width: 1.5em;
        height: 1.5em;
    }
}

.box-feature-name-icon-auth{
    width: 2em;
    height: 2em;
    background-color:#4C589E;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 0.5em 0 0;  

    img{
        width: 1.5em;
        height: 1.5em;
    }
}


.box-feature-name-icon-databases{
    width: 2em;
    height: 2em;
    background-color:#1D7AFF;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 0.5em 0 0;  

    img{
        width: 1.5em;
        height: 1.5em;
    }
}

.box-feature-name-icon-s3{
    width: 2em;
    height: 2em;
    background-color: #EB222E;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 0.5em 0 0; 
}

.box-feature-name-icon-cognito{
    width: 2em;
    height: 2em;
    background-color: #ad688b;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin: 0 0.5em 0 0;    
}

.box-feature-name-icon-api{
    width: 2em;
    height: 2em;
    background-color: #d9a840;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin: 0 0.5em 0 0;    
}


.box-feature-plus{
    p{
        margin: 0;
    }

    p:first-child{
        font-weight: bold;
    }

    p:nth-child(2){
        color: #8e949c;
    }
}

.box-feature-plus-1{
    text-align: center;
    display: flex;
    align-items: center;
    color: white;
    background-color: #EA6F2E;
    width: 1.5em;
    justify-content: center;
    height: 1.5em;
    border-radius: 50%;

    p{
        margin: 0;
    }

    p:first-child{
        font-weight: bold;
        font-size: 0.9em;
    }

    p:nth-child(2){
        color: #8e949c;
    }
}

